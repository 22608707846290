.background {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
  .background.diamond {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 400px;
    transform: translate(-50%, -50%) rotate(45deg);
}
  
.skycanvas {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    filter: blur(10px);
    width: 12.5%;
    transform: translate(-50%, -50%) scale(8);
}

@keyframes unblur {
	0%		{text-shadow:  0 0 100px #fff; opacity:0; color: rgba(255,255,255,0);}
	50%		{text-shadow:  0 0 50px #fff; }
	99%	{opacity: 1; text-shadow:  0 0 0px #fff; color: transparent; }
    100% { color: rgba(255,255,255,1); }
}

@keyframes unblur-follow {
	0%		{opacity: 0; filter: blur(10px); }
	50%		{opacity: 0;}
    100% { opacity: 1; filter: blur(0px);}
}


@keyframes blur {
	0%		{text-shadow:  0 0 0px #fff;}
	50%		{text-shadow:  0 0 50px #fff;}
    100%	{text-shadow:  0 0 100px #fff; opacity:0;}
}

.load-text {

	animation: unblur 4s ease-out;
	-webkit-animation: unblur 4s ease-out;
}

.load-more {
	animation: unblur-follow 8s ease-out;
	-webkit-animation: unblur-follow 10s ease-out;
}